import naja from "naja";

class FormsExtension {
    initialize(naja)
    {
        window.addEventListener('DOMContentLoaded', this.reloadForm.bind(this));
        // naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        //     event.detail.snippet.innerHTML = event.detail.content;
        // });
    }

    reloadForm()
    {
        $(document).on('change', '.on-change-reload-form', function(event) {
            const form = this.closest('form');
            if ($(form).hasClass('ajax'))
            {
                if ($(this).hasClass('on-change-skip-validation'))
                {
                    let values = new FormData(form);
                    values.append('onChangeSkipValidation', 'onChangeSkipValidation');
                    naja.makeRequest('POST', form.action, values).then((data) => {
                        window.Nette.init();
                    });
                } else {
                    naja.uiHandler.submitForm(form);
                }

            } else {
                $(form).submit();
            }
        });
    }
}

export default FormsExtension;
