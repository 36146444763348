class InvoiceForm {
    constructor() {
        // Inicializace při vytvoření instance
        this.initInvoiceDetail();
        this.initInvoiceItem();
    }

    initInvoiceDetail() {
        // Načtení inputů pro datum a maturity
        const dateIssueInput = document.getElementById('date-issue');
        const maturityInput = document.getElementById('maturity');
        const dateMaturityInput = document.getElementById('date-maturity');

        if (dateIssueInput && maturityInput && dateMaturityInput) {
            maturityInput.addEventListener('input', () => {
                this.updateDateMaturity(dateIssueInput, maturityInput, dateMaturityInput);
            });

            dateMaturityInput.addEventListener('input', () => {
                this.updateMaturity(dateIssueInput, maturityInput, dateMaturityInput);
            });
        }
    }

    initInvoiceItem() {
        // Načtení inputů pro položky faktury
        const invoiceItemCount = document.getElementById('invoice-item-count');
        const invoiceItemTaxRate = document.getElementById('invoice-item-tax-rate');
        const invoiceItemPrice = document.getElementById('invoice-item-price');
        const invoiceItemDiscount = document.getElementById('invoice-item-discount');
        const invoiceItemPriceBase = document.getElementById('invoice-item-price-base');
        const invoiceItemPriceTotal = document.getElementById('invoice-item-price-total');

        if (invoiceItemCount && invoiceItemTaxRate && invoiceItemPrice && invoiceItemPriceBase && invoiceItemPriceTotal) {
            invoiceItemCount.addEventListener('input', () => {
                this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
            });

            invoiceItemTaxRate.addEventListener('input', () => {
                this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
            });

            invoiceItemPrice.addEventListener('input', () => {
                this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
            });

            if (invoiceItemDiscount) {
                invoiceItemDiscount.addEventListener('input', () => {
                    this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
                });
            }
        }
    }

    updateDateMaturity(dateIssueInput, maturityInput, dateMaturityInput) {
        const dateIssue = new Date(dateIssueInput.value);
        const maturity = parseInt(maturityInput.value, 10);

        if (!isNaN(dateIssue.getTime()) && !isNaN(maturity)) {
            const newDateMaturity = new Date(dateIssue);
            newDateMaturity.setDate(dateIssue.getDate() + maturity);
            dateMaturityInput.value = newDateMaturity.toISOString().split('T')[0];
        }
    }

    updateMaturity(dateIssueInput, maturityInput, dateMaturityInput) {
        const dateIssue = new Date(dateIssueInput.value);
        const dateMaturity = new Date(dateMaturityInput.value);

        if (!isNaN(dateIssue.getTime()) && !isNaN(dateMaturity.getTime())) {
            const diffTime = dateMaturity - dateIssue;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            maturityInput.value = diffDays;
        }
    }

    updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal) {
        const itemCount = parseInt(invoiceItemCount.value, 10);
        const taxRate = parseInt(invoiceItemTaxRate.value, 10);
        const price = parseFloat(invoiceItemPrice.value);
        const discount = invoiceItemDiscount ? parseFloat(invoiceItemDiscount.value) || 0 : 0;

        if (!isNaN(itemCount) && !isNaN(taxRate) && !isNaN(price)) {
            const priceBase = itemCount * price;
            const priceTotal = priceBase * (1 + taxRate / 100) * (1 - discount / 100);

            invoiceItemPriceBase.value = priceBase.toFixed(2);
            invoiceItemPriceTotal.value = priceTotal.toFixed(2);
        }
    }

    static initialize() {
        // Vytvoření instance třídy pouze pokud jsou v DOM potřebné elementy
        new InvoiceForm();
    }
}

// Inicializace při načtení DOM
document.addEventListener('DOMContentLoaded', () => {
    InvoiceForm.initialize();
});

// Připojení na události Naja pro AJAXové operace
naja.addEventListener('complete', () => {
    InvoiceForm.initialize();
});

export default InvoiceForm;