import naja from "naja";

class LoadingIndicatorExtension {
    constructor() {
        this.loadingIndicator = document.getElementById('loading-indicator');
        this.spinner = this.loadingIndicator.querySelector('.spinner-border');
        this.triggerElement = null;
        this.intervalId = null;

        this.handleInteraction = this.handleInteraction.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.updateLoaderPosition = this.updateLoaderPosition.bind(this);
    }

    initialize() {
        document.addEventListener('click', this.handleClick, true);
        naja.addEventListener('init', this.bindUIHandler.bind(this));
        naja.uiHandler.addEventListener('interaction', this.handleInteraction);
        naja.addEventListener('start', this.handleStart);
        naja.addEventListener('complete', this.handleComplete);

        //modal hide loader hide
        document.addEventListener('modalDialogOpened', this.hideLoader.bind(this));

        let self = this;
        $(document).on('shown.bs.modal', function () {
            self.hideLoader();
        });

        $(document).on('shown.bs.modal', function () {
            self.hideLoader();
        });
    }

    bindUIHandler() {
        naja.uiHandler.addEventListener('interaction', this.handleInteraction);
    }

    handleClick(event) {
        const element = event.target.closest('.btn');
        let isSubmit = element && element.nodeName === 'INPUT' && element.type === 'submit';
        let isTargetBlank = (element && element.target === '_blank') || event.ctrlKey;
        if (element && (!isSubmit || !element.closest('form.ajax')) && !isTargetBlank) {
            if (element.dataset.loadingIndicator === 'false') {
                this.triggerElement = null;
                this.hideLoader();
            } else {
                this.triggerElement = element;
                this.showLoader();
            }
        }
    }

    handleInteraction(event) {
        const element = event.detail.element;

        if (element.dataset.loadingIndicator === 'false') {
            this.triggerElement = null;
            this.hideLoader();
        } else {
            this.triggerElement = element;
        }
    }

    handleStart(event) {
        if (this.triggerElement && this.triggerElement.dataset.loadingIndicator !== 'false') {
            this.showLoader();
        }
    }

    handleComplete() {
        this.hideLoader();
    }

    showLoader() {
        if (this.triggerElement) {
            this.updateLoaderPosition();
            this.loadingIndicator.style.display = 'flex';
            this.updateSpinnerSize();
            this.intervalId = setInterval(this.updateLoaderPosition, 100);
        }
    }

    hideLoader() {
        this.loadingIndicator.style.display = 'none';
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    updateLoaderPosition() {
        if (this.triggerElement) {
            const rect = this.triggerElement.getBoundingClientRect();
            const bodyRect = document.body.getBoundingClientRect();
            const offsetTop = rect.top - bodyRect.top;
            const offsetLeft = rect.left - bodyRect.left;

            this.loadingIndicator.style.top = `${offsetTop}px`;
            this.loadingIndicator.style.left = `${offsetLeft}px`;
            this.loadingIndicator.style.width = `${rect.width}px`;
            this.loadingIndicator.style.height = `${rect.height}px`;
        }
    }

    updateSpinnerSize() {
        if (this.loadingIndicator && this.spinner) {
            const indicatorRect = this.loadingIndicator.getBoundingClientRect();
            let size = Math.min(indicatorRect.width, indicatorRect.height) * 0.8;
            size = Math.min(size, 100); // omezit velikost na max 100px
            this.spinner.style.width = `${size}px`;
            this.spinner.style.height = `${size}px`;
        }
    }
}

export default LoadingIndicatorExtension;
