/* global naja, $, intervalFunction */
class AutoFillInputExtension {
    selectors = {
        initializer: '[data-autofill-url]',
    }
    naja = null;
    logging = false;

    initialize(naja) {
        this.naja = naja;
        this.log('auto fill naja initialize');

        window.addEventListener('DOMContentLoaded', this.init.bind(this));
        naja.addEventListener('complete', this.init.bind(this));
        naja.uiHandler.addEventListener('interaction', this.init.bind(this));
    }

    init() {
        this.log('auto fill handlers initialization');

        $(this.selectors.initializer).each((index, input) => this.initInput(input));
    }

    initInput(input) {
        this.log('auto fill attaching handles for input');
        this.log(input);

        $(input).off('.autofill');
        $(input).on('change.autofill', (e) => {
            const val = $(e.target).val();
            const url = $(e.target).data('autofill-url');

            this.log('auto fill change event triggered for input');
            this.log(e.target);

            this.sendRequest(val, url);
        });
    }

    sendRequest(val, url) {
        this.log(`auto fill sending json request val: ${val} url: ${url}`);

        this.naja.makeRequest('POST', url, {target: val})
            .then((payload) => this.processPayload(payload))
            .catch((error) => console.error(error));
    }

    processPayload(payload) {
        this.log('auto fill json response received with payload');
        this.log(payload);

        if (!payload.success) return

        for (const key in payload.data) {
            const target = '*[name="' + key + '"]';
            $(target).val(payload.data[key]);
        }
    }

    log(message) {
        if (!this.logging) return;

        console.log(message);
    }
}

export default AutoFillInputExtension;
