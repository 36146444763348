import naja from "naja";

document.addEventListener('DOMContentLoaded', function () {
    const checkboxes = document.querySelectorAll('input.invoiceFilterBtn');

    function getCheckboxStates(e) {
        const states = Array.from(checkboxes).map(checkbox => {
            return {
                [checkbox.name]: checkbox.checked ? true : false
            };
        });
        naja.makeRequest('POST', e.target.getAttribute('data-save-filter-url'), {
            values: states
        });
        return states;
    }

    // Přidá event listener na každý checkbox, který vyvolá getCheckboxStates po kliknutí
    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('click', (e) => {
            getCheckboxStates(e);
        });
    });
});

