class Ares
{
    constructor()
    {
        this.errors = [];
        this.selectedSubject = null;
        this.subjects = [];
        this.initElements();
        this.saveButton.on('click', () => this.save())
    }
    addError(message)
    {
        this.errors.push(message);
        this.showErrors();
    }
    initElements()
    {
        this.results = $('#aresResults');
        this.saveButton = $('#aresBtnSave');
        this.aresName = $('#aresName');
        this.aresIc = $('#aresIc');
        this.aresDic = $('#aresDic');
        this.aresMessage = $('#aresMessage');
        this.aresLoading = $('#aresLoading');
        this.aresData = $('#aresData');
        this.aresSubjectDetail = $('#aresSubjectDetail');
    }
    isOpen()
    {
        return this.results.length > 0 && this.saveButton.length > 0;
    }
    hasRequiredElements()
    {
        let requiredElements = [
            this.aresIc, this.aresName, this.aresMessage,
            this.aresLoading, this.aresData, this.aresSubjectDetail
        ];
        return requiredElements.every(element => element.length > 0);
    }
    validateICO(ico) {
        // IČO musí být řetězec nebo číslo a mít přesně 8 číslic
        if (typeof ico !== 'string' && typeof ico !== 'number') {
            return false;
        }

        // Převést na řetězec a odstranit případné bílé znaky
        ico = ico.toString().trim();

        if (ico.length !== 8 || !/^\d+$/.test(ico)) {
            return false;
        }

        // Výpočet kontrolního součtu podle algoritmu
        const weights = [8, 7, 6, 5, 4, 3, 2];
        let sum = 0;

        for (let i = 0; i < 7; i++) {
            sum += parseInt(ico[i]) * weights[i];
        }

        // Výpočet kontrolní číslice
        let mod = sum % 11;
        let checkDigit = mod === 0 ? 1 : 11 - mod;
        if (checkDigit === 10) checkDigit = 0;

        // Porovnat s poslední číslicí IČO
        return checkDigit === parseInt(ico[7]);
    }

    loadData()
    {
        let ic = Number(this.aresIc.val());
        let name = this.aresName.val();
        let data = { obchodniJmeno: name};

        if (this.validateICO(ic)) {
            data = { ico: [ic] };
        } else if (name.length <= 0) {
            this.addError("Není vyplněno ani jméno ani IČ, nelze hledat v registru.");
        }
        this.showSection(this.aresLoading);
        this.selectedSubject = null;
        this.postRequest(data);
    }
    postRequest(data) {
        $.ajax({
            url: 'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty/vyhledat',
            type: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data),
            success: this.showResults.bind(this),
            error: this.handleError.bind(this)
        });
    }

    showResults(data) {
        if (!data.hasOwnProperty('ekonomickeSubjekty'))
        {
            return this.addError("Neplatná odpověd ze serveru ARES");
        }
        this.subjects = data.ekonomickeSubjekty;
        this.reloadSubjects();
    }

    addSubject(subject)
    {
        this.results.append(`<option value="${subject.ico}">${subject.obchodniJmeno}</option>`);
    }

    reloadSubjects() {
        this.results.empty();
        this.subjects.forEach(subject => this.addSubject(subject));
        this.results.on('change', (e) => {
                this.selectedSubject = this.subjects.find(subject => subject.ico == e.target.value);
                this.showSelectedSubject()
        })
        this.showSection(this.aresData);
        this.results.prop('selectedIndex', 0).change();
    }

    showSelectedSubject() {
        if (this.selectedSubject == null) {
            return false;
        }

        this.aresSubjectDetail.empty();
        if (this.selectedSubject.hasOwnProperty('obchodniJmeno')) {
            this.addDetailInfo('Jméno: ', this.selectedSubject.obchodniJmeno);
        }

        if (this.selectedSubject.hasOwnProperty('ico')) {
            this.addDetailInfo('IČ: ', this.selectedSubject.ico);
        }

        if (this.selectedSubject.hasOwnProperty('dic'))
        {
            this.addDetailInfo('DIČ: ', this.selectedSubject.dic);
        }

        if (this.selectedSubject.hasOwnProperty('sidlo'))
        {
            this.addDetailInfo('Adresa:', this.selectedSubject.sidlo.textovaAdresa);
        }
    }

    addDetailInfo(label, value)
    {
        this.aresSubjectDetail.append(`<strong>${label}</strong><div>${value}</div><br>`);
    }

    handleError(xhr, status, error) {
        this.addError(error);
    }
    showErrors()
    {
        this.aresMessage.empty()
        this.errors.forEach(error => {
            this.aresMessage.append(`<div class="alert alert-danger">${error}</div>`);
        });
        this.showSection(this.aresMessage);
    }
    showSection(section)
    {
        this.aresMessage.hide();
        this.aresLoading.hide();
        this.aresData.hide();
        section.show();
    }
    save() {
        if (this.selectedSubject.hasOwnProperty('obchodniJmeno')) {
            this.aresName.val(this.selectedSubject.obchodniJmeno).trigger('change');
        }

        if (this.selectedSubject.hasOwnProperty('ico')) {
            this.aresIc.val(this.selectedSubject.ico).trigger('change');
        }

        if (this.selectedSubject.hasOwnProperty('dic'))
        {
            this.aresDic.val(this.selectedSubject.dic).trigger('change');
        }
    }

}
document.addEventListener('modalDialogOpened', (e) => {
    let ares = new Ares();
    if (!ares.isOpen())
    {
        return false;
    }

    if (ares.hasRequiredElements())
    {
        ares.loadData();
    } else {
        ares.addError("Interní chyba aplikace");
    }
});
