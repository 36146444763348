import './../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import './../../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import './../../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.cs.min.js';

$(document).ready(function()
{
    $(':text[data-provide="datepicker"]').datepicker(
        {
            language: 'cs'
        });
});
